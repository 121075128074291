.newsletter-panel{
    padding: 30px;
    background-color: #17181a;
    color: #fff;
}
.newsletter-panel h2{
    font-size: 40px;
}
.newsletter-panel p{
    color: #fff;
}
.newsletter-bg{
    background-color: #f1f1f1;
    padding: 30px 0 40px 0;
}
.newsletter-panel input, .newsletter-panel input:hover, .newsletter-panel input:focus{
    background-color: #2f3031;
    border: 1px solid #2f3031;
    color: #fff;
    box-shadow: none;
}
.contactus.social-btn{
    margin: 50px 0 30px 0;
}

.getintouchu-panel{
    padding: 30px;
    margin-top: 50px;
    background-color: #17181a;
    color: #fff;
}
.getintouchu-panel h2{
    font-size: 40px;
}
.getintouchu-panel p{
    color: #fff;
}
.newsletter-bg{
    background-color: #f1f1f1;
    padding: 30px 0 40px 0;
}
.form-bg{
    padding: 40px 20px 30px;
    background-color: #ebebeb;
}

@media (max-width: 767.98px) { 

.newsletter-panel .btn{
width: 100%;
margin-top: 20px;
}
.getintouchu-panel{
    padding: 25px 15px;
}
.newsletter-panel{
    padding: 30px 20px;
    margin: 0px;
}
.contact-buttons {
    .btn-viewmore {
        width: 100%;
        margin-bottom: 20px;
    }
}
}