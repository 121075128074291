ol>li {
    margin-bottom: 30px;

    >p {
        font-weight: bold;
    }
}
.defaultToolsDetail {
    display: block;
}

.mobileToolsDetail {
    display: none;
}

@media (max-width: 767.98px) {

    .defaultToolsDetail {
        display: none;
    }
    
    .mobileToolsDetail {
        display: block;
    }

}