.subscribe-panel{
    background-color: #17181a;
    color: #fff;
    padding: 25px;
    border-radius: 10px;
}
.subscribe-panel h3{
    font-size: 20px;
}
.subscribe-panel h2{
    font-size: 20px;
}

footer .nav-link{color: #bdbaba;}
footer .nav-link:hover, .nav-link:active{color: red;}
.social-btn{
    margin: 50px 0;
}
.social-btn .btn{
    width: 100%;
    text-align: left;
    border-radius: 0;
    font-weight: 600;
    padding: 10px 20px;
    font-size: 14px;
}
.social-btn .btn.btn-primary:hover, .social-btn .btn.btn-primary:active{
    background-color: #ededed;
    color: #000;
}
.footerbox-pad{
    padding:0 56px;
}
.right-align-icon {
    float: right;
}
.left-align-icon {
    margin-right: 25px;
}
.contactus > div:first-child {
    padding-left: 0;
}
.contactus > div:last-child {
    padding-right: 0;
}

@media (max-width: 767.98px) { 
    .social-btn .btn{
        margin-bottom: 10px;
    }
    .pad-m-0{
        padding: 0px;
    }
    .footerbox-pad{
        padding:0 25px;
    }
   
    .contactus.social-btn  > div{
        padding: 0;
    }
    footer{
        padding: 50px 20px;
    }

}
