.nav.nav-tabs {
    width: 100%;
    margin-bottom: 30px;
    
    .nav-link {
        background-color: #3b3b3b;
        color: #fff;
        &.active {
            background: linear-gradient(to right, #a60139 1%, #ce223c 29%, #ce223c 61%, #ec3f07 100%);
        }
        &:hover {
            color: #fff;
        }
        &.mrg-lft-rt-10 {
            margin: 0 10px;
        }
    }
}

@media (max-width: 480px) { 
    .mrg-lft-rt-10 {
        margin: 0;
    }
}