.accordion-wrapper {
    background: #f2f2f2;
    padding: 20px 40px;

    .accordion-button {
        font-weight: bold;
        &:focus, &:focus-visible{
            border-color: inherit !important;
            box-shadow: none !important;
            outline: none !important;
        }
        
    }

    .accordion-item,
    .accordion-button {
        background: transparent;
    }

    .accordion-button,
    .accordion-body {
        padding-left: 0;
        padding-right: 0;
    }
    .subText {
        font-size: 12px;
        padding: 30px 0 0;
    }
}