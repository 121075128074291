header{
    background-color: #fff !important; 
    font-family: "Plus Jakarta Sans", sans-serif;
    font-size: 13px;
    font-weight: 500;
    height: 90px !important;
    padding: 0 42px;
}
header.scrolled {
    box-shadow: 0 0 6px 3px rgba(183, 183, 183, 0.3);
}
header .navbar-nav .nav-link{color: #000;}
header .navbar-nav .nav-link:hover, header .navbar-nav .nav-link:active, header .navbar-nav .nav-link.active{color: #d60d59;}
header .navbar-nav a{margin:0 10px;}
header .navbar-nav .nav-link.get-quote{
    border: 1px solid #000;
    border-radius: 20px;
    padding: 6px 20px;
    font-size: 13px;
    margin: 5px;
}
header .navbar-nav #basic-nav-dropdown{margin-right: 45px;}
.dropdown-item{
    width: auto;
}
.dropdown-menu{
    border-radius: 0px;
}
.dropdown-menu.show a {
    padding: 0;
    font-size: 13px;
}
.navbar-expand-lg .navbar-nav .dropdown-menu{
    margin-top: 25px;
}
@media (max-width: 767.98px) { 
    header{
        padding: 0;
    }
}