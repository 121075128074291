.card {
    margin-bottom: 25px;
    border: 0px none !important;
}

.card .card-body {
    background-color: #3b3b3b;
    color: #fff;
    padding: 30px;
    min-height: 228px;
    .default {
        display: block;
    }
    .hover {
        display: none;
    }
}

.card .card-body:hover,
.card .card-body:active {
    color: #fff;
    background: linear-gradient(to right, #a60139 1%, #ce223c 29%, #ce223c 61%, #ec3f07 100%);
    .default {
        display: none;
    }
    .hover {
        display: block;
    }
}

.card .card-body .card-subtitle .btn, .card .card-body:hover .card-subtitle .btn {
    border: 1px solid #fff !important;
    background: transparent;
    color: #fff;
    width: 100%;
}

.card .card-body p, .card .card-body:hover p,
.card .card-body .text-more, .card .card-body:hover .text-more {
    color: #fff;
}

.card .card-body .card-subtitle .btn.btn-link {
    color: #fff;
    text-decoration: none;
}

.our-services {
    padding-right: 20px !important;
}

ul.nav {
    width: 325px;
    float: right;
}

ul.nav .nav-link {
    color: #7f7f7f;
}

ul.nav .nav-link:hover {
    color: #b02318;
}

ul li.nav-item {
    width: 157px;
    text-align: right;
}

.more-details .btn.btn-primary {
    font-size: 11px;
    margin: 10px 5px 0 0;
    border-radius: 0px;
    padding: 8px;
    text-transform: uppercase;
    background: none;
    border: 1px solid #b02318;
    // color: #7b747b !important;
}

.all-services .btn.btn-primary,
.all-services .btn.btn-primary:hover,
.all-services .btn.btn-primary:active {
    background-color: unset !important;
    padding: 5px 0px 10px 20px;
}

.all-services .left-align-icon {
    margin-right: 10px;
}

.more-services {
    background: #3b3b3b;
    color: #fff;
}

.more-services p {
    color: #fff;
    border-bottom: 1px solid #343434;
    padding: 10px 15px 10px 50px;
    margin: 0;
}

.more-services p:hover,
.more-services p:active,
.more-services p.active {
    background-color: #000;
    color: #b02318;
}

.more-services .description {
    background: linear-gradient(to right, #a60139 1%, #ce223c 29%, #ce223c 61%, #ec3f07 100%);
    padding: 0 15px 45px 25px;
}

#more-services .btn.btn-primary {
    background-color: inherit;
}

.card-body a {
    text-decoration: none;
}

.card-body img {
    padding: 0 20%;
    margin: auto;
    // border-right: 1px solid #7b747b;
    margin-top: 40px;
}
.card-body .bor-left {
    border-left: 1px solid #7b747b;
    padding-left: 30px;
}

@media (max-width: 480px) {
    .card-body img {
        padding: 0;
        border-right: 0px;
        margin-top: 90px;
    }
    .card-body .bor-left {
        border-left: 0px;
    }
    .hidden-xs {
        display: none;
    }

}


// .card-body .border-right {
//     border-right: 1px solid #7b747b;
// }


.card-body .more-details a {
    width: 48%;
    display: inline-block;
    margin-right: 2%;
    margin-top: 2%;
}

.card-body .text-more {
    text-align: right;
    // color: #b02318;
    color: #7b747b !important;
    position: relative;
    top: -20px;

    svg {
        position: relative;
        top: 9px;
        left: 5px;
        color: red;
    }
}
.card-body:hover .text-more, .card-body:active .text-more {
    color: #fff !important;
    svg {
        color: #fff !important;
    }
}

#serviceDetail {
    padding: 37px;
    background-color: #ebebeb;
}

.card-title {
    margin-top: -10px;
}

.ourservice-text {
    font-size: 18px;
    font-weight: 600;
}

.defaultServiceDetail {
    display: block;
}

.mobileServiceDetail {
    display: none;
}

@media (max-width: 767.98px) {

    .more-details .btn.btn-primary {
        border: 1px solid #b6b6b6;
        margin: 5px;
    }

    .pt-m20 {
        padding-top: 20px;

    }

    .defaultServiceDetail {
        display: none;
    }

    .mobileServiceDetail {
        display: block;
    }

}

.icon-align-right {
    float: right;
    margin-top: 4px;
}

.all-services {
    float: right;
}

.card-text {
    min-height: 75px;
    // color: #7b747b !important;
}

.accordion-right-side-header {
    padding-bottom: 20px;
}

.accordion-right-side-text {
    padding: 10px 0;
}
@media (max-width: 480px) {
    #serviceDetail {
        padding: 10px;
    }
}