/* @font-face {
  font-family: 'Satoshi';
  src: url('https://assets.website-files.com/64426c1f54e5450712b7713b/64426c1f54e5451021b7719a_Satoshi-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Satoshi';
  src: url('https://assets.website-files.com/64426c1f54e5450712b7713b/64426c1f54e545ff19b77186_Satoshi-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
} */


body {
  margin: 0;
  font-family: "Satoshi", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #000;
  line-height: 30px;
  /* padding-right: 20px;
  padding-left: 20px; */
}

p {
  color: #000;
  font-size: 16px;
}

h4 {
  font-size: 40px !important;
  font-weight: 700 !important;
  font-family: "Satoshi", sans-serif !important;
  padding-left: 15px;
  border-left: 0px solid #b02318;
  margin-bottom: 25px;
}
h5 {
  font-size: 24px !important;
  font-weight: 700 !important;
  font-family: "Satoshi", sans-serif !important;
  padding-left: 15px;
  border-left: 0px solid #b02318;
  margin-top: 25px;
  margin-bottom: 25px;
}

h6 {
  font-weight: bold;
}

.btn.btn-primary,
.btn.btn-secondary {
  border: 0px none;
  background-color: #f2f2f2;
  padding: 15px 20px;
  font-size: 15px;
  font-weight: 500;
  color: #000;

}

.btn.btn-secondary {
  background: linear-gradient(to right, #848484 1%, #9e9e9e 29%, #bababa 61%, #bfbfbf 100%);
}

.btn.btn-primary:hover,
.btn.btn-primary:active {
  background-color: #ededed !important;
  color: #b02318 !important;
}

.link-text a {
  color: #000;
  text-decoration: none;
  text-transform: uppercase;
  font-size: 14px;
}

.mr-20 {
  margin-right: 20px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-40 {
  margin-top: 40px;
}

.mt-80 {
  margin-top: 80px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-50 {
  margin-bottom: 50px;
}

.pt-50 {
  padding-top: 50px;
}

.pt-80 {
  padding-top: 80px;
}

.btn.btn-link {
  color: #595959;
  text-decoration: none;
}

.btn.btn-link:hover,
.btn.btn-link:active {
  color: #b02318;
}

.breadcrumb li.breadcrumb-item {
  font-size: 20px;
  color: #a5a5a5;
  font-weight: 600
}

.breadcrumb li.breadcrumb-item a {
  color: #a5a5a5;
  text-decoration: none;
}

.breadcrumb li.breadcrumb-item.active {
  color: #b92863;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.bs-stepper-content .btn.btn-primary,
.bs-stepper-content .btn.btn.btn-secondary {
  padding: 15px 50px;
}

.bs-stepper-content .box-wrap {
  padding: 20px;
  margin-bottom: 30px;
  box-shadow: 0px 10px 20px 0px rgba(100, 100, 100, 0.3);
}

.bs-stepper-content .btn-wrap {
  text-align: center;
}

input {
  padding: 13px !important;
}

footer {
  background-color: #2e2e2e;
  color: #bdbaba;
  border-bottom: 10px solid #fa404e;
  padding: 70px 42px;
}

footer .footer-head {
  padding-bottom: 20px;
  font-weight: 600;
}

footer ul {
  padding-left: 0;
}

footer ul li {
  list-style: none;
  font-size: 14px;
  padding-bottom: 5px;
}

.more-btn {
  margin-bottom: 50px;
}

.more-btn .btn {
  width: 100%;
  text-align: left;
  border-radius: 0;
}

.more-btn .btn:hover {
  color: #b02318;
}

.more-link {
  color: #b02318;
}

.btn-reset {
  border: 1px solid #000 !important;
}

.btn-submit {
  border: 1px solid #b02318 !important;
}

.work-items {
  width: 100%;
}

.dropdown-item:focus,
.dropdown-item:hover {
  background-color: transparent !important;
}

.list-group .list-group-item {
  margin-bottom: 10px;
  border-top: 1px solid #dee2e6 !important;
}

.sector-head {
  padding: 25px 10px 10px 25px;
  background: linear-gradient(to right, #a60139 1%, #ce223c 29%, #ce223c 61%, #ec3f07 100%);
  color: #fff;
  cursor: pointer;

  /* margin: 10px 0 20px 0; */
  .icon-align-right {
    margin-top: 0px !important;
    margin-right: 10px;
  }
}

.other-services {
  background-color: #191919;
  color: #fff;
  padding: 12px 20px 12px 20px;
  cursor: pointer;
}

.brand-box {
  background-color: #191919;
  color: #fff;
  padding: 20px;
  font-size: 20px;
  margin-top: 30px;
}

.align-center {
  align-items: center;
}

.all-services .btn.btn-primary,
.all-services .btn.btn-primary:hover,
.all-services .btn.btn-primary:active {
  background-color: unset !important;
  padding: 5px 0px 10px 20px;
}

.all-services .left-align-icon {
  margin-right: 10px;
}

.our-portfolio {
  margin: 35px 0 30px;
}

.design-services {
  background: linear-gradient(to right, #a60139 1%, #ce223c 29%, #ce223c 61%, #ec3f07 100%);
  color: #fff;
}

.design-services p {
  color: #fff;
  border-bottom: 1px solid #343434;
  padding: 0px 15px 45px 25px;
  margin: 0;
}

#design-services .btn.btn-primary {
  background-color: inherit;
}

input::placeholder {
  color: #979797 !important;
}

.sub-head {
  font-size: 18px;
  padding-top: 20px;
}

img {
  max-width: 100%;
}

.modal-body .form-check-label {
  padding: 4px 10px 10px 10px;
  max-width: calc(100% - 5px);
}

.modal-body .row>div:has(.form-select),
.modal-body .row>div:has(.form-control) {
  margin-bottom: 20px;
}

/* .HomeBanner .row>div {
  min-height: calc(100vh - 200px);
} */
.navbar-collapse {
  background-color: #fff;
  padding-bottom: 10px;
}

@media (max-width: 767.98px) {

  .more-btn .btn {
    margin-bottom: 10px;
  }

  header .navbar-nav .nav-link.get-quote {
    margin-top: 10px;
    text-align: center;
  }

  .navbar-toggler:focus {
    box-shadow: none !important;
  }

  .pad-m-10 {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .align-m-center {
    text-align: center;
  }

  .align-m-center .btn {
    width: 100%;
  }

  .HomeBanner .row>div {
    min-height: initial;
  }
}

.align-right {
  text-align: right;
}

.align-ctr {
  text-align: center;
}

.svg-right-align {
  position: relative;
  top: 4px;
  left: 5px;
}

.svg-right-align.t6 {
  top: 6px;
}

.main-content-text {
  line-height: 50px;
  font-size: 30px;

  span {
    color: #7b747b;
  }
}

.main-header-text {
  font-size: 20px;
  color: #7b747b;
  padding-bottom: 20px;
}

h2.xl.white span.grey {
  color: rgb(250, 64, 78);
}

h2.xl.white span.grey.old {
    color: #7b747b;
}
.subText {
  font-size: 12px;
  padding: 25px 0 0;
}

#latestWorks,
#serviceDetail,
#aboutUs {
  scroll-margin-top: 100px;
}

.form-check label[for="others"] {
  width: calc(100% - 5px);
}

form p[role="alert"] {
  color: #7b747b;
  font-size: 12px;
  margin-bottom: 0;
}

.form-select {
  padding: 13px 10px;
}

.mrgBtm0 {
  margin-bottom: 0 !important;
}
.mrgTop20Per {
  margin-top: 20% !important;
}
.mrgTop10Per {
  margin-top: 10% !important;
}
.mrgLft15Per {
  margin-left: 15% !important;
}
.hideOnMobile {
  display: none;
}
@media (max-width: 480px) {
  .main-content-text {
    font-size: 24px;
  }
}