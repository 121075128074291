.mt-mb {
    margin: 20px 0 50px 0;
}

.btn-viewmore.btn.btn-primary {
    border-radius: 40px !important;
    padding: 5px 16px;
}

.my-masonry-grid {
    display: -webkit-box;
    /* Not needed if autoprefixing */
    display: -ms-flexbox;
    /* Not needed if autoprefixing */
    display: flex;
    margin-left: -30px;
    /* gutter size offset */
    width: auto;
}

.my-masonry-grid_column {
    margin-right: 30px;
    /* gutter size */
    background-clip: padding-box;

    &:nth-child(3n+0) {
        margin-right: 0;
    }
}

.defaultPortfolioDetail {
    display: block;
}

.mobilePortfolioDetail {
    display: none;
}

@media (max-width: 767.98px) {

    .my-masonry-grid {
        display: block;
    }

    .my-masonry-grid_column {
        width: 100% !important;
        margin: 0;
        padding-left: 30px;
    }

    #latestWorks {}

    .defaultPortfolioDetail {
        display: none;
    }

    .mobilePortfolioDetail {
        display: block;
    }
}

.call-button {
    text-decoration: none;
    display: block;
    width: 100%;
    color: inherit;
}