
.HomeBanner{
//  padding-top: 50px;
 margin-bottom: 60px;
  color: #fff;
  background-color: #000;
  position: relative;
  overflow: hidden;
  max-height:calc(100vh - 90px);
  height:calc(100vh - 90px);
  &.old {
    color: #000;
    background-color: #fff;
  }
  .content .caption {
    z-index: 998;
  }
  .home-main {
    position: absolute;
    bottom: 0;
    width: 48%;
    margin-left: -2%;
  }
}

.HomeBannerBg {
  position: absolute;
  top: inherit;
  left: inherit;
  bottom: 0;
  right: 0;
  &.fit {
    object-fit: cover;
    max-width: 100%;
    max-height: 100%;
    height: 100%;
    width: 100%;
  }
}

.HomeBannerBg.top {
  top: 0;
  bottom: inherit;
}
.HomeBannerBg.left {
  left: 0;
  right: inherit;
}
.HomeBannerBg.right {
  right: 0;
  left: inherit;
}
.HomeBanner h2 {
    font-size: 62px;
    font-weight: 500;
    font-family: "Plus Jakarta Sans", sans-serif;
    padding-left: 44px;
}
.HomeBanner p{
  padding-right: 80px;
  font-size: 20px;
}
.HomeBanner .content {
  bottom: 0px;
}
.HomeBanner img {
  float: right;
}
.HomeContainer h4{
  margin-top: 50px;
}
.aboutus{
  padding:50px 40px 40px 45px;
  background-color: #ecedef;
  margin: 30px 0;
}
@media (max-width: 767.98px) { 

  .HomeBanner{
    color: #fff;
    padding-top: 0;
   }
   .HomeBanner p{
    padding-right:0px;
   }
   .HomeBanner h2 {
    font-size: 36px;
    padding-left: 5px;
   }
   .HomeBanner img{
    margin: 0;
   }
   .aboutus{
    padding:30px;
  }
}

@media (max-width: 480px) { 
  .HomeBannerBg {
   &.mob-rt-50{
    right: -50px;
   }
   &.mob-rt-90{
    right: -90px;
   }
   &.mob-lt-90{
    left: -90px;
   }
   &.mob-lt-150{
    left: -150px;
   }
   &.mob-btm-25{
    bottom: -25px;
   }
   &.mob-btm-40-per{
    bottom: -40%;
   }
   &.mob-rt-40-per{
    right: -40%;
   }
   &.mob-rt-25-per{
    right: -25%;
   }
   &.mob-lt-60-per{
    left: -60%;
   }
  }
  .HomeBanner {
    height:60vh;
    .content .caption {
      position: absolute;
      z-index: 998;
      bottom: 10%;
      .mrgLft15Per {
        margin-left: 0 !important;
      }
    }
    .home-main {
      position: absolute;
      bottom: 0;
      width: 100%;
    }
  }
}

@media only screen and (min-width: 1200px) {
  .big-mrg-rt-5-per {
    right: -5%;
  }
  .HomeBanner{ 
    .home-main {
      position: absolute;
      bottom: 0;
      width: 48%;
      margin-left: -7%;
    }
    .aligh-btm {
      position: absolute;
      bottom: 0;
    }
  }
}
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  
  .HomeBannerBg {
    &.tab-rt-5-per{
      right: -5%;
     }
     &.tab-lt-48-per{
      left: -48%;
     }
   }

   .HomeBanner{ 
      h2 {
        font-size: 40px;
      }
      .home-main {
        width: 40%;
      }
  }
  
}