.read-more {
    font-size: 11px;
    color: #b02318;
    cursor: pointer;
    .right-align-icon {
        margin-left: 5px;
    }
}
#ourVision, #ourStory, #ourApproach, #whyUs{
    font-size: 17px;
    scroll-margin-top: 100px;
}
.our-vision, .our-values, .our-process, .our-team{
    background-color: #f1f1f1;
    padding: 30px;
}
.our-vision h5, .our-values h5, .our-process h5, .our-team h5{
    font-size: 22px;
    padding-top: 200px;
    text-transform: uppercase;
}
#ourVision img, #ourStory img, #ourApproach img, #whyUs img {
    object-fit: cover;
    height: 100%;
    width: 100%;
    max-width: 100%;
    max-height: 502px;
}
// #ourApproach img{
//     width: 50%;
// }
.our-process h5{
    font-size: 22px;
    text-transform: uppercase;
}   