.container-pad{
    height: 100%;
    width: 100%;
    padding: 90px 0px 0px;
}
.section-pad {
    padding: 0px 44px;
    .more-pad {
        padding: 0 15px;
    }   
}
@media (max-width: 767.98px) { 
    .section-pad {
        padding: 0px 25px;   
        .container-fluid {
            padding: 0;
        }
        .more-pad {
            padding: 0 15px;
        }
    }
    .container-pad{
       padding: 90px 0px 0px;
    }
}